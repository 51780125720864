import React from 'react';
import { connect } from 'react-redux';
import { saveStatePage, setSrcollToActiveElement } from '../../common/helpers';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import tabsMenu from '../tabsMenu';

import ContentShort from '../../components/ContentShort';
import ContentFull from '../../components/ContentFull/';

import mainImg from './addiction.svg';
import tab1Img1 from './addiction-tab1-1.svg';
import tab1Img2 from './addiction-tab1-2.svg';
import tab3Img from './addiction-tab3.svg';

import './style.css';

class Addiction extends React.Component {
    constructor(props) {
      super(props);
      this.tabs = React.createRef();
    }

    state = {
      isOpen: false,
    }

    viewFullContent = () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    handleClickTab = (index) => {
      const list = this.tabs.current.querySelector('.tabs__list');
      setSrcollToActiveElement(list.children[index]);
    }

    componentDidMount() {
      saveStatePage(this.props.location.pathname, this.props.page.visit);
    }

    render() {
        return (
          <>
            <ContentShort
                title="Интернет-зависимость"
                text="Интернет-зависимость — это навязчивое желание в любую свободную минуту выходить в сеть и проводить там как можно больше времени. "
                classbutton={this.state.isOpen ? '-rotate' : ''}
                classimg="-addiction"
                src={mainImg}
                handleClick={this.viewFullContent}
            />
            <ContentFull isOpen={this.state.isOpen}>
                <div className="content-full__text">
                  <p>Зависимого подростка <b>интересует</b> только <b>виртуальный мир:</b> он часами просиживает за компьютером или не выпускает телефон из рук. <b>Реальная жизнь,</b> учёба, хобби, отношения с родными — всё это уходит на <b>второй план.</b> </p>
                </div>

                <Tabs selectedTabClassName="-active"
                      selectedTabPanelClassName="-active"
                      onSelect={index => this.handleClickTab(index)}>
                  <div className="tabs" ref={this.tabs}>
                    <div className="tabs__inner">
                      <TabList className="tabs__list">
                        {
                            tabsMenu.map((name, i) => {
                                return (
                                    <Tab className="tabs__item" key={`tab-${i}`}><div className="tabs__link">{name}</div></Tab>
                                )
                            })
                        }
                      </TabList>
                    </div>
                  </div>

                  <TabPanel className="tabs__content addiction-tab1">
                    <h2 className="tabs__title">Как распознать угрозу?</h2>
                    <div className="column -text-block">
                      <div className="column__item"><img src={tab1Img1} alt="" className="column__img addiction-tab1__img1" /></div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Зависимость бывает разной. Подросток может бесцельно <b>скролить новостную ленту</b> в соцсети, <b>смотреть</b> видеоролики, <b>общаться</b> через мессенджеры или <b>играть</b> в онлайн-игры. </li>
                          <li className="list-ul__item">Конечно, все дети так или иначе общаются, играют и смотрят видео в сети, но в случае зависимости эти занятия <b>вытесняют вообще всё</b> из жизни школьника. </li>
                          <li className="list-ul__item">Как и любая <b>зависимость,</b> интернет-зависимость <b>начинается незаметно.</b> В какой-то момент ребёнку <b>покупают смартфон,</b> планшет или перестают контролировать время за компьютером.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="column -text-block -invert">
                      <div className="column__item"><img src={tab1Img2} alt="" className="column__img addiction-tab1__img2" /></div>
                      <div className="column__item">
                        <ul className="list-ul addiction-tab1__text2">
                          <li className="list-ul__item">Школьник с головой окунается в новый для себя мир. Если родители <b>не ограничивают</b> время в интернете или психика ребёнка склонна к зависимости, то ситуация <b>может усугубиться.</b></li>
                          <li className="list-ul__item">Вот уже школьник сидит за обедом, <b>уткнувшись в смартфон,</b> а утром первым делом проверяет количество лайков и комментариев. Самое большое <b>наказание</b> для него — <b>остаться без интернета.</b></li>
                          <li className="list-ul__item">Тяжёлые случаи интернет-зависимости можно сравнить с пристрастием к алкоголю и наркотикам, когда ребёнку <b>физически плохо без интернета,</b> а виртуальная жизнь полностью вытесняет жизнь реальную. </li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content">
                    <h2 className="tabs__title">Как предупредить угрозу?</h2>
                    <ul className="list-ol">
                      <li className="list-ol__item -n1"><div className="list-ol__num">1</div>Важно создать <b>доверительную атмосферу</b> в семье, чтобы ребёнок не скрывался в сети от семейных проблем.</li>
                      <li className="list-ol__item -n2"><div className="list-ol__num">2</div><b>Не подавайте плохой пример.</b> Если ребёнок видит, что мама и папа много времени проводят в интернете, то для него это становится нормой. </li>
                      <li className="list-ol__item -n3"><div className="list-ol__num">3</div><b>Обсуждайте</b> с ребёнком его <b>виртуальную жизнь,</b> вместе смотрите любимых блогеров или как он проходит онлайн-игры. Важно не потерять с ним контакт. </li>
                      <li className="list-ol__item -n4"><div className="list-ol__num">4</div><b>Установите правила</b> пользования гаджетами, не пускайте это на самотёк — детям сложно себя контролировать. </li>
                      <li className="list-ol__item -n5"><div className="list-ol__num">5</div>Старайтесь, чтобы у ребёнка было достаточно <b>занятий и общения,</b> чтобы он не сидел в интернете от скуки. </li>
                    </ul>
                  </TabPanel>
                  <TabPanel className="tabs__content addiction-tab3">
                    <h2 className="tabs__title">Как понять, что ребёнок стал жертвой?</h2>
                    <div className="column">
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Подросток много времени <b>проводит в интернете,</b> его невозможно оторвать от этого занятия. Просьбы заняться чем-то другим он игнорирует или реагирует на них агрессивно.</li>
                          <li className="list-ul__item">Падает успеваемость, подросток становится <b>невнимательным,</b> рассеянным и <b>раздражительным.</b> У него нарушается сон и аппетит. Он перестаёт выполнять какие-то дела по дому и становится всё более <b>неряшливым.</b></li>
                          <li className="list-ul__item">У него снижается двигательная активность: он всё больше сидит дома, никуда не ходит, забрасывает спорт. </li>
                        </ul>
                      </div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">У ребёнка <b>пропадает интерес</b> к реальной <b>жизни,</b> к общению вне сети. Как только вы забираете у него гаджет, он не знает, куда себя деть.</li>
                        </ul>
                        <img src={tab3Img} alt="" className="column__img addiction-tab3__img" />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content addiction-tab4">
                    <h2 className="tabs__title">Что теперь делать?</h2>
                    <div className="column">
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Во-первых, <b>признайте факт</b> зависимости. Из этой точки можно начинать дальнейшую работу  — своими силами или в сложных случаях с помощью психолога.  </li>
                        </ul>
                      </div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Организуйте подростку <b>интересный досуг:</b> совместные походы, выставки, концерты. Иногда достаточно вырвать человека из виртуального мира, отправив его в лагерь или на дачу без интернета. </li>
                        </ul>
                      </div>
                    </div>
                    <div className="dark-frame">
                      <p>Важен <b>личный пример.</b> Всей семье стоит <b>ограничить</b> бесцельное <b>использование гаджетов</b>.  Например, договориться убирать телефоны во время семейных ужинов. А для ребёнка — составить график, когда ему можно выходить в сеть, и строго его придерживаться.</p>
                    </div>
                  </TabPanel>
                </Tabs>
            </ContentFull>
          </>
        )
    }
}

const mapStateToProps = function(store) {
  return {
    page: store.pagesState.status,
  };
};

export default connect(mapStateToProps)(Addiction);
