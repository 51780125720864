import React from 'react';
import { connect } from 'react-redux';
import {NavLink} from 'react-router-dom';
import { ReactComponent as IconDone } from './ic-done.svg';
import pages from '../../pages/configPages';
import { setSrcollToActiveElement } from '../../common/helpers';

import './style.css';

import {xray, nameMap} from '../../xrayInstance';

class Menu extends React.Component {
    constructor(props) {
      super(props);
      this.menu = React.createRef();
    }

    componentDidMount() {
      const activeMenuLink = this.menu.current.querySelector('.-active');

      if (activeMenuLink) {
        setSrcollToActiveElement(activeMenuLink.parentElement);
      }
    }

    componentDidUpdate() {
      const activeMenuLink = this.menu.current.querySelector('.-active');

      if (activeMenuLink) {
        setSrcollToActiveElement(activeMenuLink.parentElement);
      }
    }

    render() {
        return (
          <div className="menu" ref={this.menu}>
            <div className="menu__inner">
              <ul className="menu__list">
                {
                    pages.map(({url, name}) => {
                        let classList = 'menu__link';

                        if (this.props.page.visit.includes(url) && this.props.page.current !== url) {
                          classList += ' -visit';
                        }

                        return (
                            <li className="menu__item" key={url}>
                              { name === 'share'
                                ? <NavLink onClick={() => xray.send('click', {i: `menu_${nameMap[name]}`})} to={url} className={`${classList} -last`} activeClassName="-active"><span><IconDone /></span></NavLink>
                                : <NavLink onClick={() => xray.send('click', {i: `menu_${nameMap[name]}`})} to={url} className={classList} activeClassName="-active"><span>{name}</span></NavLink>
                              }
                            </li>
                        )
                    })
                }
              </ul>
            </div>
          </div>
        )
    }
}

const mapStateToProps = function(store) {
  return {
    page: store.pagesState.status,
  };
};

export default connect(mapStateToProps)(Menu);
