import store from '../store';
import {changePage} from '../actions/page-actions';

const saveStatePage = (current, visit) => {

  if (!visit.includes(current)) {
    visit.push(current);
  }

  store.dispatch(changePage({
    visit: visit,
    current: current
  }));
};

const setSrcollToActiveElement = (element) => {
  const bodyWidth = Math.max(
        document.body.scrollWidth, document.documentElement.scrollWidth,
        document.body.clientWidth, document.documentElement.clientWidth,
    );

  if (bodyWidth < 768) {
    element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
    });
  }
};

export {
  saveStatePage,
  setSrcollToActiveElement,
};
