import React from 'react';

import './style.css';

class ContentFull extends React.Component {
    render() {
        return (
            <div className={`content-full ${this.props.isOpen  ? ' -open' : ''}`}>
              { this.props.children }
            </div>
        )
    }
}

export default ContentFull;
