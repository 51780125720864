import React from 'react';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactComponent as IconRight } from '../Button/ic-right.svg';

import pages from '../../pages/configPages';

import Routing from '../../Routing';
import Header from '../Header';
import Footer from '../Footer';
import Anons from '../Anons';
import Info from '../Info';
import Menu from '../Menu';

import './style.css';

import {xray, nameMap} from '../../xrayInstance';

class Page extends React.Component {
    componentDidMount() {
      console.log("Зашли");
    }

    render() {
          const visitedCount = this.props.page.visit.length;
          let buttonNext = {};

          if (visitedCount < (pages.length - 1)) {
            const result = pages.find(item => !this.props.page.visit.includes(item.url));

            if (result !== 'undefined') {
              buttonNext = {
                name: result.name,
                url: result.url
              };
            } else {
              buttonNext = {
                name: pages[0].name,
                url: pages[0].url
              };
            }

          } else {
            buttonNext = {
              name: "Завершить",
              url: "/share"
            };
          }

          return (
              <>
                <Header />
                <main className="page__main wrap">
                  <Anons />
                  <Menu />

                  <div className="content">
                    <div className="content__frame">
                      <Routing />
                    </div>
                  </div>
            
                  { this.props.page.current === '/share'
                    ? <Info />
                    : <div className="next">
                        <NavLink 
                          to={buttonNext.url}
                          className="button -blue -size-l -icon"
                          onClick={() => xray.send('click', {i: `buttonNext_${nameMap[buttonNext.name]}`})}
                        >
                          { buttonNext.name }
                          <IconRight />
                        </NavLink>
                      </div>
                  }

                </main>
                <Footer />
              </>
          )
    }
}

const mapStateToProps = function(store) {
  return {
    page: store.pagesState.status,
  };
};

export default connect(mapStateToProps)(Page);
