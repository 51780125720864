import React from 'react';
import { connect } from 'react-redux';
import { saveStatePage, setSrcollToActiveElement } from '../../common/helpers';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import tabsMenu from '../tabsMenu';

import ContentShort from '../../components/ContentShort';
import ContentFull from '../../components/ContentFull/';

import mainImg from './content.svg';
import tab1Img from './content-tab1.svg';
import tab3Img from './content-tab3.svg';

import './style.css';

class Content extends React.Component {
    constructor(props) {
      super(props);
      this.tabs = React.createRef();
    }

    state = {
      isOpen: false,
    }

    viewFullContent = () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    handleClickTab = (index) => {
      const list = this.tabs.current.querySelector('.tabs__list');
      setSrcollToActiveElement(list.children[index]);
    }

    componentDidMount() {
      saveStatePage(this.props.location.pathname, this.props.page.visit);
    }

    render() {
        return (
          <>
            <ContentShort
                title="Опасный контент"
                text="Это одна из самых очевидных проблем киберпространства. Нежелательные публикации, группы и сайты находятся в свободном доступе, а дети очень любопытны."
                classbutton={this.state.isOpen ? '-rotate' : ''}
                classimg="-content"
                src={mainImg}
                handleClick={this.viewFullContent}
            />
            <ContentFull isOpen={this.state.isOpen}>
                <div className="content-full__text">
                  <p>Практически каждый школьник сталкивался с недетскими или опасными материалами. Это и <b>порносайты,</b> и группы, посвященные <b>самоубийствам или</b> пропаганде <b>анорексии,</b> и статьи, рассказывающие <b>об изготовлении наркотиков.</b> Подобный контент может нанести серьёзный <b>удар по психике</b> подростка. </p>
                </div>

                <Tabs selectedTabClassName="-active"
                      selectedTabPanelClassName="-active"
                      onSelect={index => this.handleClickTab(index)}>
                  <div className="tabs" ref={this.tabs}>
                    <div className="tabs__inner">
                      <TabList className="tabs__list">
                        {
                            tabsMenu.map((name, i) => {
                                return (
                                    <Tab className="tabs__item" key={`tab-${i}`}><div className="tabs__link">{name}</div></Tab>
                                )
                            })
                        }
                      </TabList>
                    </div>
                  </div>

                  <TabPanel className="tabs__content content-tab1">
                    <h2 className="tabs__title">Как распознать угрозу?</h2>
                    <div className="column">
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item"><b>Угроза</b> возникает в тот момент, когда у ребёнка <b>появляется смартфон,</b> планшет или личный компьютер с выходом в интернет. Информация находится в свободном доступе, обсуждается в соцсетях, в чатах и на форумах — <b>достаточно завести там аккаунт.</b></li>
                          <li className="list-ul__item">Бывают просто нежелательные материалы для подростков. Например, различные <b>эротические</b> ресурсы, «взрослая» реклама или паблики про <b>азартные игры</b> и <b>алкоголь.</b></li>
                          <li className="list-ul__item">А бывают реально опасные. Это группы, пропагандирующие <b>самоубийства,</b> убийства, экстремизм, <b>анорексию,</b> рассказывающие об изготовлении <b>наркотиков</b> или взрывчатки. Последние могут не только повлиять на детскую психику, но и привести к <b>жертвам.</b></li>
                          <li className="list-ul__item">Все помнят истории с так называемыми «группами смерти» в социальных сетях. Администраторы этих сообществ <b>готовят подростков к самоубийству,</b> выдавая задания некоего «квеста».</li>
                          <li className="list-ul__item">Или массовые и абсолютно легальные группы, <b>пропагандирующие анорексию.</b> В этих сообществах состоят тысячи недовольных своим весом школьниц. Они вместе <b>худеют до 40 кг,</b> хвастаются выпирающими костями.</li>
                          <li className="list-ul__item">В сети тысячи подобных деструктивных групп и сайтов. И <b>ориентированы</b> они в основном <b>на детей и подростков.</b></li>
                        </ul>
                      </div>
                      <div className="column__item">
                        <img src={tab1Img} alt="" className="column__img content-tab1__img" />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content">
                    <h2 className="tabs__title">Как предупредить угрозу?</h2>
                    <ul className="list-ol">
                      <li className="list-ol__item -n1"><div className="list-ol__num">1</div><b>Ребёнок</b> должен понимать, что он <b>для вас важен,</b> что он может прийти к вам с любой проблемой и вы его поймёте поймёте его и поддержите.</li>
                      <li className="list-ol__item -n2"><div className="list-ol__num">2</div><b>Разговаривайте</b> с детьми. Когда у ребёнка есть <b>чёткое понимание:</b> что такое хорошо и что такое плохо — его не так-то просто сбить с пути. </li>
                      <li className="list-ol__item -n3"><div className="list-ol__num">3</div>Учите подростка <b>мыслить критически.</b> Он должен уметь анализировать и фильтровать информацию, составлять собственное мнение.</li>
                      <li className="list-ol__item -n4"><div className="list-ol__num">4</div>Старайтесь <b>следить за онлайн-жизнью</b> ребёнка и его интересами. Это, конечно, не значит, что нужно читать личную переписку и ежедневно мониторить историю браузера.</li>
                      <li className="list-ol__item -n5"><div className="list-ol__num">5</div>Пользуйтесь техническими возможностями, которые <b>ограничивают</b> нежелательный <b>контент.</b> Так браузеры <b>блокируют опасные сайты,</b> а на смартфонах, планшетах и игровых приставках есть функция <b>родительского контроля.</b></li>
                      <li className="list-ol__item -n5"><div className="list-ol__num">6</div>Честно <b>расскажите</b> ребёнку о деятельности <b>деструктивных групп.</b> Причём сделать это лучше до того, как он заведёт себе страницы в соцсетях.</li>
                    </ul>
                  </TabPanel>
                  <TabPanel className="tabs__content content-tab3">
                    <h2 className="tabs__title">Как понять, что ребёнок стал жертвой?</h2>
                    <div className="column -text-block -invert">
                      <div className="column__item">
                        <img src={tab3Img} alt="" className="column__img content-tab3__img" />
                      </div>
                      <div className="column__item">
                        <p>Вы замечаете, что ребёнок снова и <b>снова возвращается</b> к какой-то «запретной» теме. Он вбивает в поисковик ключевые слова и сидит на тематических сайтах.</p>
                        <p>Следите за изменениями в <b>поведении подростка.</b> Обращайте внимание на то, сколько он спит, общается ли с друзьями и как долго сидит в интернете.</p>
                      </div>
                    </div>
                    <h3 className="tabs__subtitle">Тревожные признаки:</h3>
                    <div className="column">
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Ребёнок становится слишком <b>послушным</b> и <b>отрешённым.</b> Или плаксивым и <b>раздражительным.</b> Любое изменение привычного поведения должно насторожить родителей. </li>
                          <li className="list-ul__item">У него <b>нарушается сон и аппетит.</b> Он может не спать всю ночь или практически ничего не есть. </li>
                          <li className="list-ul__item">На теле появляются <b>порезы и шрамы.</b> Он рисует странные <b>мрачные рисунки,</b> использует <b>непонятный сленг.</b></li>
                        </ul>
                      </div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Он много <b>сидит в интернете</b> и общается с виртуальными друзьями. При этом реальные друзья, школа, семья и хобби уходят на второй план. </li>
                          <li className="list-ul__item">Его окружает <b>депрессивный фон:</b> музыка, фильмы, посты и комментарии в соцсетях, рассуждения о смерти. </li>
                          <li className="list-ul__item">Ребёнок ведёт себя как перед дальней дорогой: <b>отдаёт долги,</b> убирается в комнате, <b>раздаёт</b> личные <b>вещи.</b></li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content content-tab4">
                    <h2 className="tabs__title">Что теперь делать?</h2>
                    <div className="advice">Чаще <b>обнимайте</b> ребёнка, говорите, что любите, рассказывайте о своих делах, спрашивайте, как дела у него. Искренне <b>интересуйтесь</b> его жизнью.</div>
                    <div className="column">
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Попробуйте аккуратно, но честно поговорить с ним. Расскажите о том, какие <b>механизмы</b> действуют в <b>деструктивных группах,</b> на что рассчитывают их создатели, какого поведения они добиваются и <b>как</b> этому <b>противостоять.</b></li>
                          <li className="list-ul__item">Придумайте какие-нибудь <b>крутые занятия вне сети.</b> Это может быть спортивный лагерь или поездка на дачу, где не ловит интернет. </li>
                        </ul>
                      </div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Не бойтесь разговаривать на острые темы и <b>отвечать на неудобные вопросы.</b> Ребёнок всё равно обо всём узнает — если не от вас, то в интернете.</li>
                          <li className="list-ul__item"><b>Обратитесь к психологу,</b> если ребёнок не идёт на контакт, а его поведение вас тревожит. </li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
            </ContentFull>
          </>
        )
    }
}

const mapStateToProps = function(store) {
  return {
    page: store.pagesState.status,
  };
};

export default connect(mapStateToProps)(Content);
