import React from 'react';

import './style.css';

import logo from './logo.svg';

const Header = () => (
    <header className="header wrap">
  		<a href="https://mail.ru/" className="header__logo" rel="noopener noreferrer" target="_blank"><img src={logo} alt="mail.ru" /></a>
  	</header>
);

export default Header;
