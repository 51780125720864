import React from 'react';
import { connect } from 'react-redux';
import { saveStatePage, setSrcollToActiveElement } from '../../common/helpers';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import tabsMenu from '../tabsMenu';

import ContentShort from '../../components/ContentShort';
import ContentFull from '../../components/ContentFull/';

import mainImg from './cyberruming.svg';
import tab2Img from './cyberruming-tab2.svg';
import tab3Img1 from './cyberruming-tab3-1.svg';
import tab3Img2 from './cyberruming-tab3-2.svg';

import './style.css';

class Cyberruming extends React.Component {
    constructor(props) {
      super(props);
      this.tabs = React.createRef();
    }

    state = {
      isOpen: false,
    }

    viewFullContent = () => {
      console.log('hi');
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    handleClickTab = (index) => {
      const list = this.tabs.current.querySelector('.tabs__list');
      setSrcollToActiveElement(list.children[index]);
    }

    componentDidMount() {
      saveStatePage(this.props.location.pathname, this.props.page.visit);
    }

    render() {
        return (
          <>
            <ContentShort
                title="Кибергруминг"
                text="Кибергруминг, или онлайн-груминг&nbsp;&mdash; это создание доверительных отношений с&nbsp;ребёнком в&nbsp;интернете для его дальнейшей сексуальной эксплуатации."
                classbutton={this.state.isOpen ? '-rotate' : ''}
                classimg="-cyberruming"
                src={mainImg}
                handleClick={this.viewFullContent}
            />
            <ContentFull isOpen={this.state.isOpen}>
                <div className="content-full__text">
                  <p>Преступники <b>под вымышленным именем</b> добавляются <b>«в друзья»</b> к ребёнку в социальной сети или в чате. Они <b>формируют</b> с ним близкие <b>отношения</b>, чтобы получить интимные фото, а потом шантажировать ими.</p>
                </div>

                <Tabs selectedTabClassName="-active"
                      selectedTabPanelClassName="-active"
                      onSelect={index => this.handleClickTab(index)}>
                  <div className="tabs" ref={this.tabs}>
                    <div className="tabs__inner">
                      <TabList className="tabs__list">
                        {
                            tabsMenu.map((name, i) => {
                                return (
                                    <Tab className="tabs__item" key={`tab-${i}`}><div className="tabs__link">{name}</div></Tab>
                                )
                            })
                        }
                      </TabList>
                    </div>
                  </div>

                  <TabPanel className="tabs__content">
                    <h2 className="tabs__title">Как распознать угрозу?</h2>
                    <ul className="list-ol">
                      <li className="list-ol__item -n1"><div className="list-ol__num">1</div>Начинается всё довольно безобидно: у ребёнка появляется новый <b>виртуальный «друг».</b> Его страницу трудно отличить от страницы реального подростка — та же музыка, те же приколы и картинки.</li>
                      <li className="list-ol__item -n2"><div className="list-ol__num">2</div>Незнакомец разговаривает с ребёнком на интересные для него темы, <b>делает комплименты,</b> всячески вовлекает в общение. Он просит <b>никому не рассказывать</b> о дружбе, пишет о доверии и особом отношении.</li>
                      <li className="list-ol__item -n3"><div className="list-ol__num">3</div>В какой-то момент он <b>начинает отправлять</b> якобы свои <b>фотографии с сексуальным подтекстом.</b> Вначале это происходит ненавязчиво, как бы между прочим.</li>
                      <li className="list-ol__item -n4"><div className="list-ol__num">4</div>Потом он просит ребёнка прислать в ответ  свои <b>откровенные снимки.</b> Если тот отказывается это делать, то незнакомец начинает давить, говорить о дружбе, о том, что «я же тебе отправил фото, так нечестно».</li>
                      <li className="list-ol__item -n5"><div className="list-ol__num">5</div>Шантаж начинается, когда подросток сдаётся и отправляет свои фото.  Преступник <b>грозится разместить их в сети,</b> отправить друзьям или родителям — если ребёнок не выполнит его просьбу. Обычно ему нужны новые снимки, видео, деньги или личная встреча.</li>
                      <li className="list-ol__item -n6"><div className="list-ol__num">6</div>Ребёнок <b>боится,</b> что взрослые узнают его тайну. Он снова и снова общается с незнакомцем и <b>выполняет его желания.</b> А если школьник соглашается на встречу, то это может закончится ещё более трагически. </li>
                    </ul>
                  </TabPanel>
                  <TabPanel className="tabs__content cyberruming-danger">
                    <h2 className="tabs__title">Как предупредить угрозу?</h2>
                    <div className="column cyberruming-danger__main">
                      <div className="column__item">
                        <div className="advice">Расскажите ребёнку об&nbsp;основах безопасного сетевого общения:</div>
                        <div className="cyberruming-danger__img"><img src={tab2Img} alt="" /></div>
                      </div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item"><b>Не&nbsp;стоит доверять</b> новым и&nbsp;даже старым <b>виртуальным знакомым,</b> если ты&nbsp;не&nbsp;знаешь их&nbsp;лично.</li>
                          <li className="list-ul__item">Тем более <b>нельзя встречаться</b> с&nbsp;виртуальными друзьями <b>без ведома родителей,</b> даже если вы&nbsp;давно общаетесь в&nbsp;сети.</li>
                          <li className="list-ul__item">Ни с кем <b>нельзя делиться</b> своими или чужими <b>откровенными фото и видео.</b> Ты никогда не узнаешь о судьбе этих файлов.</li>
                          <li className="list-ul__item">Личную информацию нужно <b>держать в секрете.</b> Не ставь геотеги и не пиши в открытом доступе домашний адрес, телефон и имена родителей. </li>
                          <li className="list-ul__item">Всё, что попало в сеть, останется там навсегда. Тебе <b>не должно быть стыдно</b> ни за одно сообщение, ни за один снимок. </li>
                        </ul>
                      </div>
                    </div>
                    <div className="dark-frame cyberruming-danger__extra">
                        <h3 className="advice -small">Родителям также стоит держать руку на пульсе: </h3>
                        <div className="column -col3">
                          <div className="column__item"><b>Будьте в&nbsp;курсе</b> сетевой жизни ребёнка: интересуйтесь, какие сайты он&nbsp;посещает, обменивайтесь веселыми видео, задавайте вопросы.</div>
                          <div className="column__item"><b>Расскажите о кибергруминге,</b> научите ребёнка уверенно <b>говорить «нет»,</b> если ему что-то не нравится. Он должен знать, как действовать, если его запугивают.</div>
                          <div className="column__item">Важен контакт и&nbsp;<b>доверительные отношения,</b> чтобы ребёнок в&nbsp;случае проблем первым делом обратился&nbsp;бы к&nbsp;вам.</div>
                        </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content cyberruming-victim">
                    <h2 className="tabs__title">Как понять, что ребёнок стал жертвой?</h2>
                    <div className="column -text-block -valign -invert">
                      <div className="column__item">
                        <img src={tab3Img1} alt="" className="column__img -i1" />
                      </div>
                      <div className="column__item">
                        <p>У&nbsp;подростка <b>меняется поведение:</b> он&nbsp;становится более напряжённым, грустным, агрессивным и&nbsp;замкнутым. Школьник <b>теряет интерес к&nbsp;учёбе</b> и любимым занятиям. Он&nbsp;<b>всё время сидит в&nbsp;интернете,</b> после чего выглядит ещё более расстроенным.</p>
                      </div>
                    </div>
                    <div className="column -text-block -valign">
                      <div className="column__item">
                        <img src={tab3Img2} alt="" className="column__img -i2" />
                      </div>
                      <div className="column__item">
                        <p>Ребёнок может чаще <b>просить карманные деньги</b> и&nbsp;быстрее их&nbsp;тратить. Подросток меньше общается с&nbsp;друзьями, его активность в&nbsp;социальных сетях падает&nbsp;&mdash; он&nbsp;<b>не&nbsp;выкладывает посты</b> и&nbsp;<b>фотографии.</b></p>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content cyberruming-todo">
                    <h2 className="tabs__title">Что теперь делать?</h2>
                    <div className="column -text-block">
                      <div className="column__item">
                        <p>Если интимные фото или видео уже попали к преступникам, важно чтобы ребёнок смог <b>рассказать об этом родителям</b> или другим близким взрослым — учителю, школьному психологу, старшему брату.</p>
                      </div>
                      <div className="column__item">
                        <p>Поддержите его, не ругайте, не обвиняйте в произошедшем. Ребёнок должен знать, что он не один, что близкие <b>не дадут его в обиду,</b> а злоумышленник понесёт заслуженное наказание и не причинит вреда. </p>
                      </div>
                    </div>
                    <div className="dark-frame -m1-tab4">
                      <p>Стоит немедленно <b>прекратить</b> всё <b>общение с преступником,</b> сделать скриншоты переписки и обратиться в полицию.</p>
                    </div>
                  </TabPanel>
                </Tabs>
            </ContentFull>

          </>
        )
    }
}

const mapStateToProps = function(store) {
  return {
    page: store.pagesState.status,
  };
};

export default connect(mapStateToProps)(Cyberruming);
