import React from 'react';

import Button from '../Button';

import './style.css';

class ContentShort extends React.Component {
    render() {
        return (
            <div className="content-short">
              <div className={`content-short__img ${this.props.classimg}`}>
                { this.props.src &&
                  <img src={this.props.src} alt="" />
                }
              </div>
              <div className="content-short__text">
                <h1 className="content-short__title">{this.props.title}</h1>
                <p>{this.props.text}</p>

                <Button
                    class={`-transparent -size-s -icon ${this.props.classbutton}`}
                    name="Подробнее"
                    icon="down"
                    onClick={this.props.handleClick}
                />
              </div>
            </div>
        )
    }
}

export default ContentShort;
