export const xray = window.xray;

export const nameMap = {
    'Кибергруминг': 'cybergrooming',
    'Травля в интернете': 'internet_harassment',
    'Интернет-зависимость': 'internet_addiction',
    'Опасный контент': 'dangerous_content',
    'Секстинг': 'sexting',
    'Мошенничество': 'fraud',
    'Завершить': 'end',
    'share': 'share'
  }