import React from 'react';

import './style.css';

const Anons = () => (
    <div className="anons">
      <h2 className="anons__title">Защитите детей в интернете</h2>
      <div className="anons__text">
        <p>
          Интернет полон опасностей, а дети доверчивы и любопытны. Задача родителя — защитить своего ребёнка от киберугроз. Узнайте, как это сделать, и проверьте себя в <a href="http://safe.mail.ru/test?utm_source=safe_landing_main&utm_medium=other&utm_campaign=safe" target="_blank" rel="noopener noreferrer">игре</a>
        </p>
      </div>
    </div>
);

export default Anons;
