import React from 'react';
import { ReactComponent as IconOk } from './ic-ok.svg';
import { ReactComponent as IconVk } from './ic-vk.svg';
import { ReactComponent as IconTw } from './ic-tw.svg';
import { ReactComponent as IconFb } from './ic-fb.svg';

import './style.css';

const Social = () => (
    <ul className="social">
      <li className="social__item -title">Подписывайтесь на новости:</li>
      <li className="social__item">
        <a href="https://ok.ru/group/52522315940083" className="social__link -ok" rel="noopener noreferrer" target="_blank">
          <IconOk />
        </a>
      </li>
      <li className="social__item">
        <a href="https://vk.com/public39554210" className="social__link -vk" rel="noopener noreferrer" target="_blank">
          <IconVk />
        </a>
      </li>
      <li className="social__item">
        <a href="https://twitter.com/e_mail_ru" className="social__link -tw" rel="noopener noreferrer" target="_blank">
          <IconTw />
        </a>
      </li>
      <li className="social__item">
        <a href="https://www.facebook.com/241037109344282" className="social__link -fb" rel="noopener noreferrer" target="_blank">
          <IconFb />
        </a>
      </li>
    </ul>
);

export default Social;
