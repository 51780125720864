import Cyberruming from '../pages/Cyberruming';
import Bullying from '../pages/Bullying';
import Addiction from '../pages/Addiction';
import Content from '../pages/Content';
import Sexting from '../pages/Sexting';
import Fraud from '../pages/Fraud';
import Final from '../pages/Final';

const pages = [
    {
        name: 'Кибергруминг',
        url: '/cybergrooming',
        component: Cyberruming,
    },
    {
        name: 'Травля в интернете',
        url: '/bullying',
        component: Bullying,
    },
    {
        name: 'Интернет-зависимость',
        url: '/addiction',
        component: Addiction,
    },
    {
        name: 'Опасный контент',
        url: '/content',
        component: Content,
    },
    {
        name: 'Секстинг',
        url: '/sexting',
        component: Sexting,
    },
    {
        name: 'Мошенничество',
        url: '/fraud',
        component: Fraud,
    },
    {
        name: 'share',
        url: '/share',
        component: Final,
    },
];

export default pages;
