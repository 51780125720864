import React from 'react';
import { ReactComponent as IconOk } from './ic-ok.svg';
import { ReactComponent as IconVk } from './ic-vk.svg';
import { ReactComponent as IconTw } from './ic-tw.svg';
import { ReactComponent as IconFb } from './ic-fb.svg';

import './style.css';

import {xray} from '../../xrayInstance';

class Share extends React.Component {
    share = (type) => {
      const href = encodeURIComponent('https://safe.mail.ru');

      xray.send('click', {i: `share_${type}`})

      switch (type) {
        case 'ok':
  	      window.open('http://www.ok.ru/dk?st.cmd=addShare&st.s=1&st._surl=' + href + '&st.comments=', '', 'height=480,width=640');
  	      break;
  	    case 'vk':
  	      window.open('http://vk.com/share.php?url=' + href, '', 'height=480,width=640');
  	      break;
  	    case 'fb':
  	      window.open('https://www.facebook.com/sharer/sharer.php?u=' + href, '', 'height=480,width=640');
  	      break;
  	    case 'tw':
  	      window.open('https://twitter.com/intent/tweet?url=' + href, '', 'height=480,width=640');
  	      break;
  	    default:
  	      break;
  	  }
    }

    render() {
        return (
            <ul className="share">
              <li className="share__item -title">Подписывайтесь на новости:</li>
              <li className="share__item">
                <div className="share__link -ok" onClick={() => this.share('ok')}>
                  <IconOk />
                </div>
              </li>
              <li className="share__item">
                <div className="share__link -vk" onClick={() => this.share('vk')}>
                  <IconVk />
                </div>
              </li>
              <li className="share__item">
                <div className="share__link -tw" onClick={() => this.share('tw')}>
                  <IconTw />
                </div>
              </li>
              <li className="share__item">
                <div className="share__link -fb" onClick={() => this.share('fb')}>
                  <IconFb />
                </div>
              </li>
            </ul>
        )
    }
}

export default Share;
