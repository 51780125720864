import React from 'react';
import { connect } from 'react-redux';
import { saveStatePage, setSrcollToActiveElement } from '../../common/helpers';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import tabsMenu from '../tabsMenu';

import ContentShort from '../../components/ContentShort';
import ContentFull from '../../components/ContentFull/';

import mainImg from './bullying.svg';
import tab1Img1 from './bullying-tab1-1.svg';
import tab1Img2 from './bullying-tab1-2.svg';
import tab2Img1 from './bullying-tab2-1.svg';
import tab2Img2 from './bullying-tab2-2.svg';
import tab3Img1 from './bullying-tab3-1.svg';
import tab3Img2 from './bullying-tab3-2.svg';

import './style.css';

class Bullying extends React.Component {
    constructor(props) {
      super(props);
      this.tabs = React.createRef();
    }

    state = {
      isOpen: false,
    }

    viewFullContent = () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    handleClickTab = (index) => {
      const list = this.tabs.current.querySelector('.tabs__list');
      setSrcollToActiveElement(list.children[index]);
    }

    componentDidMount() {
      saveStatePage(this.props.location.pathname, this.props.page.visit);
    }

    render() {
        return (
          <>
            <ContentShort
                title="Травля в интернете"
                text="У травли в интернете много видов: кибербуллинг, интернет-моббинг, флейм и другие. Но суть явления одна — один человек или группа агрессивных людей в течение длительного времени преследует кого-то в сети."
                classbutton={this.state.isOpen ? '-rotate' : ''}
                classimg="-bullying"
                src={mainImg}
                handleClick={this.viewFullContent}
            />
            <ContentFull isOpen={this.state.isOpen}>
                <div className="content-full__text">
                  <p>Интернет-травля, по сравнению с просто травлей, имеет <b>ряд особенностей:</b> неограниченная аудитория, <b>высокая скорость</b> распространения информации,  относительная <b>анонимность</b> преследователей и <b>невозможность</b> физически <b>выйти</b> из ситуации.</p>
                </div>

                <Tabs selectedTabClassName="-active"
                      selectedTabPanelClassName="-active"
                      onSelect={index => this.handleClickTab(index)}>
                  <div className="tabs" ref={this.tabs}>
                    <div className="tabs__inner">
                      <TabList className="tabs__list">
                        {
                            tabsMenu.map((name, i) => {
                                return (
                                    <Tab className="tabs__item" key={`tab-${i}`}><div className="tabs__link">{name}</div></Tab>
                                )
                            })
                        }
                      </TabList>
                    </div>
                  </div>

                  <TabPanel className="tabs__content bullying-tab1">
                    <h2 className="tabs__title">Как распознать угрозу?</h2>
                    <div className="column -text-block">
                       <div className="column__item"><img src={tab1Img1} alt="" className="column__img bullying-tab1__img1" /></div>
                       <div className="column__item">
                          <ul className="list-ul">
                             <li className="list-ul__item">Кибербуллингом занимаются <b>в основном подростки</b> — с 11 до 16 лет. В этом возрасте человек особенно психологически неустойчив, он остро реагирует на критику и оскорбления, ему важно быть частью группы и круто выглядеть.</li>
                             <li className="list-ul__item">Травля может начаться <b>ещё в офлайне,</b> в тех местах, где ребёнок проводит много времени, чаще всего <b>в школе,</b> а уже после перейти в интернет. Бывает и наоборот — сетевая агрессия перерастает в реальную.</li>
                             <li className="list-ul__item">Преследователи, которых называют «булли», «тролли», «мобберы» и «киберхулиганы», пишут <b>гадости в соцсетях</b> и мессенджерах, размещают компрометирующие видео на YouTube, публикуют <b>личные фотографии</b> жертвы.</li>
                          </ul>
                       </div>
                    </div>
                    <div className="column -text-block -invert">
                       <div className="column__item"><img src={tab1Img2} alt="" className="column__img bullying-tab1__img2" /></div>
                       <div className="column__item">
                          <ul className="list-ul bullying-tab1__text2">
                             <li className="list-ul__item">Булли обычно высмеивают какие-то <b>физические недостатки</b> или особенности человека&nbsp;&mdash; внешность, манеру одеваться или поведение. При этом жертвой может стать <b>абсолютно любой ребёнок.</b></li>
                             <li className="list-ul__item">С последствиями кибербуллинга детям приходится справляться в реальном мире: <b>жить в страхе,</b> переводиться в другие школы, работать с психологом. </li>
                             <li className="list-ul__item">В самых трагических случаях ребёнок может причинить себе или обидчикам какой-то физический вред или даже <b>совершить суицид.</b> </li>
                          </ul>
                       </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content bullying-tab2">
                    <h2 className="tabs__title">Как предупредить угрозу?</h2>
                    <div className="column -text-block">
                      <div className="column__item bullying-tab2__block1">
                        <div className="advice">Расскажите ребёнку об основах безопасного сетевого общения:</div>
                        <img src={tab2Img1} alt="" className="column__img bullying-tab2__img1" />
                      </div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item"><b>Не делись личной информацией</b> в общем доступе. Не ставь геотеги и не пиши домашний адрес, адрес школы, телефон и имена родителей.</li>
                          <li className="list-ul__item"><b>Следи за</b> своими <b>постами и комментариями</b> в соцсетях и чатах. Не публикуй сообщения и фотографии, которые можно использовать против тебя. </li>
                          <li className="list-ul__item"><b>Не стесняйся</b> обратиться <b>к родителям</b> или другим значимым взрослым, если кто-то в сети начинает переходить твои личные границы. </li>
                        </ul>
                      </div>
                    </div>
                    <div className="column -text-block -invert">
                      <div className="column__item bullying-tab2__block2">
                        <div className="advice">Родителям также стоит держать руку на пульсе:</div>
                        <img src={tab2Img2} alt="" className="column__img bullying-tab2__img2" />
                      </div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Объясните, как выглядит <b>кибербуллинг</b> и к чему он может привести. Подросток должен понимать, что любое агрессивное общение — это <b>не норма.</b></li>
                          <li className="list-ul__item">Самое важное — <b>контакт</b> и доверительные отношения, чтобы ребёнок в случае проблем первым делом <b>обратился бы к вам.</b></li>
                          <li className="list-ul__item"><b>Не разрешайте</b> заводить <b>аккаунты</b> в социальных сетях детям, <b>возраст</b> которых <b>меньше рекомендуемого</b> на этих ресурсах. </li>
                          <li className="list-ul__item"><b>«Подружитесь»</b> с подростком <b>в сети.</b> Делитесь приколами, картинками и видео, если он, конечно, не против.</li>
                          <li className="list-ul__item">Пользуйтесь <b>программами</b> родительского <b>контроля.</b> Они позволяют оценивать активность ребёнка в интернете.</li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content bullying-tab3">
                    <h2 className="tabs__title">Как понять, что ребёнок стал жертвой?</h2>
                    <div className="column -text-block -valign -invert">
                      <div className="column__item">
                        <img src={tab3Img1} alt="" className="column__img bullying-tab3__img1" />
                      </div>
                      <div className="column__item">
                        <p>Ребёнок становится <b>замкнутым и грустным,</b> неохотно идёт на контакт, снижается успеваемость в школе. Он часто проверяет телефон или подолгу, но без удовольствия, <b>сидит за компьютером.</b> У него могут пропадать вещи или деньги — булли часто занимаются вымогательством. </p>
                      </div>
                    </div>
                    <div className="column -text-block -valign">
                      <div className="column__item">
                        <img src={tab3Img2} alt="" className="column__img bullying-tab3__img2" />
                      </div>
                      <div className="column__item">
                        <p>Родителям нужно помнить, что <b>ребёнок</b> может стать <b>не только жертвой</b> кибербуллинга, <b>но и преследователем.</b> Причём в последнем случае он либо осознанно примыкает к агрессивному большинству, либо по незнанию воспринимает происходящее как шутку.</p>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content">
                    <h2 className="tabs__title">Что теперь делать?</h2>
                    <div className="column -text-block">
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Важно, чтобы ребёнок нашёл в себе силы <b>рассказать</b> о травле <b>родителям</b> или другим значимым взрослым. Чтобы уже вместе с ними, сохраняя спокойствие, выработать тактику поведения.</li>
                          <li className="list-ul__item">Ребёнку <b>не стоит</b> отвечать, оправдываться, ввязываться в дискуссии или как-то <b>реагировать</b> на сообщения. Если агрессор не будет знать о реакции, то весь смысл травли для него теряется.</li>
                          <li className="list-ul__item">На булли нужно <b>жаловаться</b> администраторам веб-ресурсов, заносить их в чёрные списки. Лучше <b>закрыть</b> свой <b>профиль,</b> сменить пароли и даже адрес электронной почты. </li>
                        </ul>
                      </div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">На некоторое время подростку лучше <b>снизить</b> или вовсе прекратить <b>сетевую активность.</b> Преследователи могут отвлечься и потерять интерес к жертве.</li>
                          <li className="list-ul__item">Но <b>не запрещайте</b> ребёнку <b>пользоваться интернетом.</b> По статистике, большинство детей боятся сказать родителям о травле именно из-за страха лишиться доступа в сеть.</li>
                          <li className="list-ul__item">Сделайте <b>скриншоты переписки,</b> они могут пригодиться, если ситуация перейдёт в юридическую плоскость.</li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
            </ContentFull>
          </>
        )
    }
}

const mapStateToProps = function(store) {
  return {
    page: store.pagesState.status,
  };
};

export default connect(mapStateToProps)(Bullying);
