import { combineReducers } from 'redux';

// Reducers
import pagesReducer from './pages';

// Combine Reducers
var reducers = combineReducers({
  pagesState: pagesReducer
});

export default reducers;
