import * as types from '../actions/types';

const initialState = {
  status: {
    visit: [],
    current: ''
  }
};

const pagesReducer = function(state = initialState, action) {

  switch(action.type) {

    case types.CHANGE_PAGE:
      return Object.assign({}, state, { status: action.data });

    default:
      return state;

  }

}

export default pagesReducer;
