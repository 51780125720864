import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import pages from '../pages/configPages';

class Routing extends React.Component {
    render() {
        return (
            <Switch>
                {
                    pages.map(({url, component}) => {
                        return (
                            <Route path={url} component={component} key={url} />
                        )
                    })
                }

                <Redirect from='/' exact to='/cybergrooming' />
            </Switch>
        );
    }
}

export default withRouter(Routing);
