import React from 'react';
import { connect } from 'react-redux';
import { saveStatePage } from '../../common/helpers';


import Share from '../../components/Share';

import './style.css';

class Final extends React.Component {
    state = {
      isOpen: false,
    }

    viewFullContent = () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    componentDidMount() {
      saveStatePage(this.props.location.pathname, this.props.page.visit);
    }

    render() {
        return (
          <div className="final">
            <div className="final__title">Теперь вы знаете, как защитить детей от киберпреступников</div>
            Пройдите <a href="http://safe.mail.ru/test?utm_source=safe_landing&utm_medium=other&utm_campaign=safe" target="_blank" rel="noopener noreferrer">тест</a>, чтобы проверить, как вы всё усвоили и расскажите о киберугрозах близким и другим родителям. Это важно.
            <Share />
          </div>
        )
    }
}

const mapStateToProps = function(store) {
  return {
    page: store.pagesState.status,
  };
};

export default connect(mapStateToProps)(Final);
