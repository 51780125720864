import React from 'react';

import Img1 from './info-img1.svg';
import Img2 from './info-img2.svg';

import './style.css';

import {xray} from '../../xrayInstance';

const Info = () => (
    <div className="info">
      <div className="info__item">
        <img src={Img1} alt="" />
        <p>
          Смотрите мультфильмы о <b>детской безопасности</b> в городе от Дети Mail.ru и «Лиза Алерт»<br/>
          <a onClick={() => xray.send('click', {i: `link_bezopasno_deti_mail_ru`})} href="https://bezopasno.deti.mail.ru/" rel="noopener noreferrer" target="_blank">bezopasno.deti.mail.ru </a>
        </p>
      </div>
      <div className="info__item">
        <img src={Img2} alt="" />
        <p>
          Узнайте о том, как мы <b>защищаем</b> ваш аккаунт в Почте Mail.ru<br/>
          <a onClick={() => xray.send('click', {i: `link_security_mail_ru`})} href="http://security.mail.ru/" rel="noopener noreferrer" target="_blank">security.mail.ru</a>
        </p>
      </div>
    </div>
);

export default Info;
