import React from 'react';
import { connect } from 'react-redux';
import { saveStatePage, setSrcollToActiveElement } from '../../common/helpers';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import tabsMenu from '../tabsMenu';

import ContentShort from '../../components/ContentShort';
import ContentFull from '../../components/ContentFull/';

import mainImg from './sexting.svg';
import tab1Img from './sexting-tab1.svg';
import tab3Img from './sexting-tab3.svg';

import './style.css';

class Sexting extends React.Component {
    constructor(props) {
      super(props);
      this.tabs = React.createRef();
    }

    state = {
      isOpen: false,
    }

    viewFullContent = () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    handleClickTab = (index) => {
      const list = this.tabs.current.querySelector('.tabs__list');
      setSrcollToActiveElement(list.children[index]);
    }

    componentDidMount() {
      saveStatePage(this.props.location.pathname, this.props.page.visit);
    }

    render() {
        return (
          <>
            <ContentShort
                title="Секстинг"
                text="Секстинг — калька английского слова «sexting», которое происходит от сочетания слов «sex» и «texting». Это пересылка подростками друг другу своих интимных фото, видео или сообщений через соцсети, мессенджеры или чаты."
                classbutton={this.state.isOpen ? '-rotate' : ''}
                classimg="-sexting"
                src={mainImg}
                handleClick={this.viewFullContent}
            />
            <ContentFull isOpen={this.state.isOpen}>
                <div className="content-full__text">
                  <p>Этот термин появился ещё в 2005 году в Новой Зеландии, когда 13-летняя девочка выставила свои эротические снимки на сайте знакомств. А сейчас, <b>в эпоху</b> мобильного <b>интернета,</b> мессенджеров и хороших камер, это явление приобрело <b>особый размах.</b></p>
                </div>

                <Tabs selectedTabClassName="-active"
                      selectedTabPanelClassName="-active"
                      onSelect={index => this.handleClickTab(index)}>
                  <div className="tabs" ref={this.tabs}>
                    <div className="tabs__inner">
                      <TabList className="tabs__list">
                        {
                            tabsMenu.map((name, i) => {
                                return (
                                    <Tab className="tabs__item" key={`tab-${i}`}><div className="tabs__link">{name}</div></Tab>
                                )
                            })
                        }
                      </TabList>
                    </div>
                  </div>

                  <TabPanel className="tabs__content sexting-tab1">
                    <h2 className="tabs__title">Как распознать угрозу?</h2>
                    <div className="column -invert">
                      <div className="column__item"><img src={tab1Img} alt="" className="column__img sexting-tab1__img" /></div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Сам секстинг, по мнению психологов, <b>не опасен</b> для подростковой психики. Это один из способов познания собственной сексуальности. Но вот его <b>последствия</b> могут быть <b>печальными.</b></li>
                          <li className="list-ul__item">Иногда один из участников переписки начинает <b>шантажировать</b> другого <b>интимными фотографиями.</b> И ребёнок снова и снова отправляет агрессору свои приватные снимки, отдаёт карманные деньги или ворует их у родителей.</li>
                          <li className="list-ul__item">А бывает, что вся переписка <b>оказывается в соцсетях</b> или школьных чатах. Ребёнка начинают <b>травить</b> и в интернете, и <b>в офлайне.</b></li>
                          <li className="list-ul__item">Личная встреча с агрессором может привести к <b>реальному насилию.</b> Последствия секстинга серьёзно <b>травмируют психику</b> или даже становятся <b>причиной самоубийства.</b></li>
                          <li className="list-ul__item">У секстинга бывают и <b>отложенные последствия.</b> Подросток вырастает, начинает искать работу или мечтает об учёбе в престижном вузе. В некоторых случаях нехорошая веб-история может <b>помешать его планам.</b></li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content">
                    <h2 className="tabs__title">Как предупредить угрозу?</h2>
                    <ul className="list-ol">
                      <li className="list-ol__item -n1"><div className="list-ol__num">1</div>Расскажите ребёнку о том, что вся опубликованная в интернете <b>информация останется</b> там навсегда и ты никогда <b>не сможешь проследить судьбу</b> отправленных кому-то фото.</li>
                      <li className="list-ol__item -n2"><div className="list-ol__num">2</div>Объясните подростку, как происходит <b>взаимодействие в секстинге,</b> что это за явление, какие у него <b>риски и последствия.</b></li>
                      <li className="list-ol__item -n3"><div className="list-ol__num">3</div>Важно, чтобы в семье были <b>теплые</b> и доверительные <b>отношения.</b> Ребёнок должен знать о вашей поддержке.</li>
                    </ul>
                  </TabPanel>
                  <TabPanel className="tabs__content sexting-tab3">
                    <h2 className="tabs__title">Как понять, что ребёнок стал жертвой?</h2>
                    <div className="column -invert -valign -text-block">
                      <div className="column__item"><img src={tab3Img} alt="" className="column__img sexting-tab3__img" /></div>
                      <div className="column__item">
                        <div className="advice">Обратите внимание на поведение ребёнка. Если оно отличается от привычного, возможно, это последствия секстинга: </div>
                      </div>
                    </div>
                    <div className="column -text-block">
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Он много времени проводит в интернете, при этом прячет гаджеты от родителей и запаролил все страницы в социальных сетях.</li>
                          <li className="list-ul__item">Он закрывается в комнате, чтобы сделать очередные снимки. Вы находите его откровенные фотографии в соцсетях.</li>
                        </ul>
                      </div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Школьник выглядит замкнутым, озабоченным и встревоженным.</li>
                          <li className="list-ul__item">Его травят и обижают в школе. При этом ребёнок говорит, что всё нормально, и не разрешает вам вмешиваться.</li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content sexting-tab4">
                    <h2 className="tabs__title">Что теперь делать?</h2>
                    <div className="advice"><b>Не ругайте</b> и не пугайте <b>подростка,</b> если он стал жертвой секстинга или просто признался в том, что отправил кому-то интимные снимки.</div>
                    <div className="column">
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item"><b>Поддержите</b> и утешьте своего <b>ребёнка</b>, даже если считаете, что он сделал что-то плохое. Не обвиняйте, это только усугубит ситуацию. </li>
                          <li className="list-ul__item"><b>Сделайте скриншот</b> переписки. <b>Свяжитесь с родителями подростков,</b> которые в ней тоже участвовали, расскажите им всё, что знаете.</li>
                        </ul>
                      </div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item"><b>Выясните подробности:</b> кому он отправлял фотографии, почему и на каких ресурсах их стали публиковать. Оцените, насколько ситуация фатальна, и действуйте.</li>
                          <li className="list-ul__item">Немедленно <b>сообщите в полицию,</b> если выясните, что с ребёнком общался <b>взрослый.</b></li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
            </ContentFull>
          </>
        )
    }
}

const mapStateToProps = function(store) {
  return {
    page: store.pagesState.status,
  };
};

export default connect(mapStateToProps)(Sexting);
