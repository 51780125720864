import React from 'react';

import Social from '../Social';

import './style.css';

import logo from './logo-mail.svg';

const Footer = () => (
    <footer className="footer">
      <div className="wrap">
        <Social />

        <div className="footer__nav">
          <ul className="footer__menu">
            <li className="footer__menuitem"><a href="https://corp.mail.ru/ru/" rel="noopener noreferrer" target="_blank">О компании</a></li>
            <li className="footer__menuitem"><a href="https://help.mail.ru/mail" rel="noopener noreferrer" target="_blank">Помощь</a></li>
          </ul>
          <a href="https://mail.ru/" className="footer__logo" rel="noopener noreferrer" target="_blank"><img src={logo} alt="mail.ru" /></a>
        </div>
      </div>
    </footer>
);

export default Footer;
