import React from 'react';
import { ReactComponent as IconRight } from './ic-right.svg';
import { ReactComponent as IconDown } from './ic-down.svg';

import './style.css';

class Button extends React.Component {
    render() {
        return (
            <button
                className={`button ${this.props.class}`}
                onClick={this.props.onClick}
            >
                { this.props.name }

                { this.props.icon === 'right' &&
                  <IconRight />
                }

                { this.props.icon === 'down' &&
                  <IconDown />
                }
            </button>
        )
    }
}

export default Button;
