import React from 'react';
import { connect } from 'react-redux';
import { saveStatePage, setSrcollToActiveElement } from '../../common/helpers';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import tabsMenu from '../tabsMenu';

import ContentShort from '../../components/ContentShort';
import ContentFull from '../../components/ContentFull/';

import mainImg from './fraud.svg';
import tab1Img1 from './fraud-tab1-1.svg';
import tab1Img2 from './fraud-tab1-2.svg';
import tab2Img from './fraud-tab2.svg';

import './style.css';

class Fraud extends React.Component {
    constructor(props) {
      super(props);
      this.tabs = React.createRef();
    }

    state = {
      isOpen: false,
    }

    viewFullContent = () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    handleClickTab = (index) => {
      const list = this.tabs.current.querySelector('.tabs__list');
      setSrcollToActiveElement(list.children[index]);
    }

    componentDidMount() {
      saveStatePage(this.props.location.pathname, this.props.page.visit);
    }

    render() {
        return (
          <>
            <ContentShort
                title="Мошенничество"
                text="В сети существует огромное количество мошеннических схем, рассчитанных именно на детей. Преступники полагаются на доверчивость, любопытство, некритичность и невнимательность школьников."
                classbutton={this.state.isOpen ? '-rotate' : ''}
                classimg="-fraud"
                src={mainImg}
                handleClick={this.viewFullContent}
            />
            <ContentFull isOpen={this.state.isOpen}>
                <div className="content-full__text">
                  <p>Конечная <b>цель мошенников,</b> как правило, <b>деньги,</b> которые списываются с банковских карт, телефонных счетов, электронных кошельков. В некоторых случаях целью могут быть <b>личные данные</b> пользователей.  </p>
                </div>

                <Tabs selectedTabClassName="-active"
                      selectedTabPanelClassName="-active"
                      onSelect={index => this.handleClickTab(index)}>
                  <div className="tabs" ref={this.tabs}>
                    <div className="tabs__inner">
                      <TabList className="tabs__list">
                        {
                            tabsMenu.map((name, i) => {
                                return (
                                    <Tab className="tabs__item" key={`tab-${i}`}><div className="tabs__link">{name}</div></Tab>
                                )
                            })
                        }
                      </TabList>
                    </div>
                  </div>

                  <TabPanel className="tabs__content fraud-tab1">
                    <h2 className="tabs__title">Как распознать угрозу?</h2>
                    <div className="column -text-block">
                      <div className="column__item"><img src={tab1Img1} alt="" className="column__img fraud-tab1__img1" /></div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Дети часто получают <b>фишинговые письма</b> якобы от известных компаний. Ребёнок читает это письмо, переходит по ссылке или вводит какие-то данные в форму обратной связи. И открывает преступникам <b>доступ к паролям</b> и номерам банковских карт.</li>
                          <li className="list-ul__item">Или ребёнок видит <b>баннер</b> со ссылкой типа: «Узнай, кто заходил к тебе на страницу». Переходит по ней и попадает на сторонний сайт, где мошенники просят <b>указать свой телефон.</b> Затем присылают на этот номер ссылку и <b>списывают</b> с телефонного счёта <b>деньги</b></li>
                          <li className="list-ul__item">Вообще, любое сообщение, в котором есть просьба <b>пройти по ссылке</b> должно <b>насторожить</b>. Даже если оно пришло от знакомого. Часто мошенники взламывают аккаунт и от лица друзей рассылают спам или <b>просят деньги.</b></li>
                        </ul>
                      </div>
                    </div>
                    <div className="column -text-block -invert">
                      <div className="column__item"><img src={tab1Img2} alt="" className="column__img fraud-tab1__img2" /></div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Иногда подросток находит какую-то <b>лёгкую работу</b> в интернете. Он выполняет её, но когда сумма на счёте становится достаточной для вывода, <b>кошелёк блокируется.</b> Чтобы разблокировать его, мошенники требуют оплату.</li>
                          <li className="list-ul__item">Часто дети <b>сами тратят</b> родительские деньги. Они <b>легко верят,</b> что <b>выиграли</b> какой-то крупный <b>приз.</b> А мошенники просят их оплатить сущую мелочь — доставку или налог.</li>
                          <li className="list-ul__item">Если аккаунт ребёнка привязан к вашей банковской карте, то можно ждать самых странных покупок. Дети легко выкидывают <b>тысячи</b> на прокачку аккаунтов в онлайн-играх. <b>Устанавливают</b> огромное количество <b>платных</b> приложений и игр. </li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content fraud-tab2">
                    <h2 className="tabs__title">Как предупредить угрозу?</h2>
                    <div className="column fraud-tab2__top">
                      <div className="column__item fraud-tab2__block">
                        <div className="advice">Расскажите ребёнку об основах безопасного сетевого общения:</div>
                        <img src={tab2Img} alt="" className="column__img fraud-tab2__img" />
                      </div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Важно <b>критически относиться</b> к любой информации в сети, <b>не кликать</b> на подозрительные баннеры, <b>не переходить</b> по неочевидным ссылкам, не вводить персональные данные на непонятных сайтах. </li>
                          <li className="list-ul__item"><b>Следить за</b> приватностью и <b>безопасностью.</b> Не оставлять личные данные в соцсетях и мессенджерах, закрыть свой профиль от незнакомцев. Придумывать <b>сложные</b> и индивидуальные <b>пароли.</b></li>
                          <li className="list-ul__item">Скачивать только <b>легальные программы</b> на официальных сайтах. Установить <b>надёжный антивирус</b> и регулярно обновлять программное обеспечение на своих гаджетах.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="dark-frame">
                        <h3 className="advice -small">Родителям также стоит <b>держать руку</b> на <b>пульсе:</b></h3>
                        <div className="column">
                          <div className="column__item">Важно, чтобы ребёнок <b>не боялся</b> подойти и <b>рассказать о пропаже</b> денег со счёта. Если он испугается вашего гнева, то в итоге вы можете потерять гораздо больше.</div>
                          <div className="column__item">Заведите <b>отдельную карту</b> для онлайн-покупок с минимальной суммой на счёте. Важно чтобы мошенники, пользуясь доверчивостью ребёнка, <b>не могли добраться до</b> ваших <b>основных средств.</b> </div>
                        </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content fraud-tab3">
                    <h2 className="tabs__title">Как понять, что ребёнок стал жертвой?</h2>
                    <div className="advice">Самое очевидное — <b>пропажа денег</b> со счёта. Даже исчезновение десяти рублей, которую редко кто замечает, может быть началом атаки кибермошенников на ваш кошелёк.</div>
                    <div className="column">
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">У ребёнка <b>меняется поведение:</b> он выглядит озабоченным и <b>грустным,</b> уходит от разговора, прячет глаза, делает всё, чтобы бы вы не узнали баланс веб-кошелька или мобильного телефона. </li>
                        </ul>
                      </div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Если с аккаунта ребёнка <b>рассылается спам или просьбы перевести деньги, то, скорее всего, его профиль украли.</b> Оповестите об этом его виртуальных друзей, чтобы они случайно не перевели деньги мошенникам.</li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="tabs__content fraud-tab3">
                    <h2 className="tabs__title">Что теперь делать?</h2>
                    <div className="advice">Как только вы узнали о мошенничестве — <b>обратитесь</b> в свой <b>банк.</b> Действовать нужно <b>оперативно.</b> Напишите заявление об отмене перевода и запросите выписку по транзакциям.</div>
                    <div className="column">
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Если ребёнок куда-то вводил данные карты, то нужно <b>заблокировать</b> её как можно скорее, иначе ваши средства могут быть украдены.</li>
                        </ul>
                      </div>
                      <div className="column__item">
                        <ul className="list-ul">
                          <li className="list-ul__item">Сообщите администрации сайта, если аккаунт ребёнка взломали. Важно вовремя заблокировать мошеннические рассылки. <b>Смените пароли</b> на связанных ресурсах. </li>
                        </ul>
                      </div>
                    </div>
                    <div className="dark-frame -m1-tab4">
                      <p>Не стоит сильно ругать ребёнка, даже если он сам потратил деньги с вашей карты. Но он <b>должен понять,</b> что поступил плохо и <b>ощутить последствия</b> своего поступка.</p>
                    </div>
                  </TabPanel>
                </Tabs>
            </ContentFull>
          </>
        )
    }
}

const mapStateToProps = function(store) {
  return {
    page: store.pagesState.status,
  };
};

export default connect(mapStateToProps)(Fraud);
